/* .bold-text {
  font-weight: 1500;
} */
.gradient-text {
  background-image: linear-gradient(
    90deg,
    /* rgb(1, 177, 140),
    rgb(2, 86, 63), */ #359d9e,
    #7ce495
      /* rgb(0, 108, 85),
    rgb(0, 123, 95),
    rgb(2, 153, 120),
    rgb(1, 177, 140) */
      /* #fcdf56,
    #fbd173,
    #f6e0af,
    #f1f1f1,
    #e4e4e4 */
  );
  background-clip: text;
  color: transparent;
  /* font-weight: 800; */
  /* font-size: 32px; */
  /* opacity: 1; */
}

.gray-text {
  font-size: 30px;
  /* color: #343838; */
  color: #292d34;
  /* color: rgb(102, 102, 102); */

  /* color: rgb(107 114 128); */
  /* opacity: 0.8; */
}

.headerRight {
  display: flex;
  align-items: center;
  /* background-color: red; */
}

/* .listContainer (controlled in Profile.css) */

.marginBottom {
  margin-bottom: 50px;
}

.navButton {
  margin-right: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-decoration: none;
  color: rgb(102, 102, 102);
  /* font-size: 13px; */
}

.titley {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  /* font-family: Axiforma; */
  /* font: 800 39px/53px Axiforma, Arial, serif; */
  color: #292d34;
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
  /* max-width: 500px; */
}

.topBann {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: lightgray 1px solid;
  margin-bottom: 30px;
}

.headerContainery {
  background-image: linear-gradient(
    to right,
    rgb(2, 86, 63),
    rgb(0, 108, 85),
    rgb(0, 123, 95),
    rgb(2, 153, 120),
    rgb(1, 177, 140)
      /* rgb(0, 90, 92),
    rgb(0, 118, 112),
    rgb(0, 147, 128),
    rgb(0, 176, 139),
    rgb(56, 205, 145) */
  );
  color: white;
  /* padding-left: 5px;
  padding-right: 20px; */
  /* position: sticky;
  top: 0;
  z-index: 10; */
  /* padding: 5px; */
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.header_icon {
  object-fit: contain;
  /* height: 40px; */
  /* height: 70px; */
  max-width: 100%;
  /* max-width: fit-content; */
  /* padding-right: 10px; */
  /* margin-left: 20px; */
  /* border: red 1px solid; */
}

.custom_header_icon {
  object-fit: contain;
  /* height: 40px; */
  /* height: 70px; */
  max-width: 50%;
  /* max-height: 50%; */
  /* max-width: fit-content; */
  /* padding-right: 10px; */
  /* margin-left: 20px; */
  /* border: red 1px solid; */
}

.logoutSpan {
  cursor: pointer;
  border: white 1px solid;
  padding: 5px;
  border-radius: 5px;
}

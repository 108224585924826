.bttnRw {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
}

.endInput {
  margin-bottom: 15px;
}

.signUpFormy {
  border-radius: 10px;
  width: 300px;
  /* height: 320px; */
  background-color: white;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  padding: 25px 30px;
}

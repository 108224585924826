.authModal {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  /* position: absolute; */
  z-index: 9999;
  top: 0;
  justify-content: center;
  align-items: center;

  font-family: "Poppins", sans-serif;
}

.clickToSignIn {
  text-decoration: none;
  font-size: 11px;
  line-height: 2px;
  color: rgb(102, 102, 102);
  /* margin-top: 10px; */
  opacity: 0.8;
  cursor: pointer;
  text-decoration: underline;
}

.closeLogin {
  position: absolute;
  top: 0;
  right: 14px;
  font-size: 30px;
  transform: rotate(45deg);
  cursor: pointer;
}

.createListingDiv {
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  position: absolute;
  top: 0;
  z-index: 1;
}

.donateBanner {
  margin-top: 35px;
  margin-left: 10px;
  margin-right: 10px;
}
.customBanner {
  /* margin-top: 35px; */
  margin-left: 10px;
  margin-right: 10px;
}

.donateHeader {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 30px;
  /* color: #343838; */
  color: rgb(102, 102, 102);
  font-weight: 500px;
}

.donateSubheader {
  font-family: "Poppins", sans-serif;
  color: rgb(102, 102, 102);
  /* font-size: 0.8rem; */
  opacity: 0.8;
}

input {
  padding-left: 8px;
  height: 30px;
  width: 96%;
  margin-bottom: 15px;
  border: 1px solid lightgray;
  border-radius: 3px;
  font-size: 12px;
}

.listContainer {
  /* margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px; */
  /* background-color: rebeccapurple; */
  display: flex;
  /* display: inline-block; */
  flex-direction: row;
  flex-wrap: wrap;
  /* width: max-content;
  max-width: 90vw; */
  justify-content: space-around;
  /* justify-content: center; */
  max-width: 900px;
  margin: auto;
  /* align-self: center; */
  /* margin: auto; */
  /* align-items: center; */
}

.mustSignIn {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}

.mustSignInContainer {
  text-align: center;
}

.profileDiv {
  min-height: 100vh;
}

.signOutDiv {
  margin-top: 35px;
  margin-bottom: 35px;
  margin-left: 10px;
  margin-right: 10px;
}

.signUpForm {
  border-radius: 10px;
  width: 300px;
  /* height: 320px; */
  background-color: white;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  padding: 25px 30px;
}

.signUpForm button {
  width: 100%;
  border-radius: 10px;
  border: none;
  outline: none;
  height: 35px;
  background: orange;
  color: #fff;
  font-size: 14px;
  margin-bottom: 10px;
  cursor: pointer;
}

.signUpForm > h1 {
  text-align: center;
}

.signUpForm > p {
  text-decoration: none;
  font-size: 11px;
  line-height: 2px;
  color: rgb(102, 102, 102);
  /* margin-top: 10px; */
  opacity: 0.8;
  cursor: pointer;
  text-decoration: underline;
}

.signUpForm > span {
  font-size: 11px;
  /* line-height: 2px; */
  color: red;
}

.sub {
  color: rgb(102, 102, 102);
  font-size: 0.8rem;
  opacity: 0.8;
  /* background-color: red; */
}

.subDiv {
  margin-bottom: 20px;
}

.yourListingsDiv {
  margin-top: 35px;
  margin-left: 10px;
  margin-right: 10px;
}

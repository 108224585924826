.form {
  border-radius: 10px;
  width: 90%;
  max-width: 750px;
  /* height: 320px; */
  background-color: white;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  padding: 25px 30px;
  max-height: 90vh;
  overflow-y: auto;
}

.form > h1 {
  text-align: center;
}
.form > h2 {
  font-size: 1rem;
}

.form > h3 {
  font-size: 0.8rem;
}

.modally {
  display: flex;
  /* width: 100vw;
  height: 100vh; */
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  justify-content: center;
  align-items: center;

  font-family: "Poppins", sans-serif;
  z-index: 10;
}

.newFormForStripe {
  background-color: white;
  width: 90%;
  max-width: 750px;
  padding: 25px;
  max-height: 90vh;
  overflow-y: auto;
  /* border-radius: 10px; */
}

.badgesRow {
  /* background-color: yellow; */
  margin-bottom: 5px;
}

.boldTx {
  font-weight: bold;
}

.carousel {
  max-height: 100vh;
  /* max-width: 5vw; */
  /* background-color: black; */
  /* border: lightgray 1px solid; */
}

.caroDiv {
  /* max-height: 80vh; */
  object-fit: contain;
}

.carouselImage {
  /* height: 80vh; */
  max-height: 50vh;
  /* width: 100%; */
  object-fit: contain;
}
.copyLinkButton {
  background-color: #f2f2f2;
  /* color: white; */
  border: #f2f2f2 1px solid;
  padding-top: 5px;
  padding-bottom: 5px;
}
.creatorListingDiv {
  /* background-color: lightgray; */
  border: lightgray 1px solid;
  border-radius: 10px;
  /* margin: 10px; */
  padding: 30px;
}

.grayInfoText {
  color: gray;
  margin-top: 10px;
  /* padding: 15px; */
  /* background-color: lightgray; */
}
.grayInfoTextHeader {
  color: gray;
  margin-top: 10px;
  /* text-decoration: underline; */
  cursor: pointer;
  /* padding: 15px; */
  /* background-color: lightgray; */
  /* border-bottom: gray 1px solid; */
  align-items: center;
}
.grayTx {
  color: gray;
  padding: 15px;
  background-color: lightgray;
}
.greenTx {
  color: darkgreen;
  padding: 15px;
  background-color: lightgreen;
}
/* 
.redTx {
  color: danger;
} */
.linkCopiedButton {
  background-color: #f2f2f2;
  /* color: white; */
  border: #f2f2f2 1px solid;
  padding-top: 5px;
  padding-bottom: 5px;
}

.listingDiv {
  padding-top: 20px;
}

.spanLink {
  text-decoration: underline;
  cursor: pointer;
}

.strikeThroughPrice {
  text-decoration: line-through;
}

.pledgeConfirmedMain {
  /* margin: auto; */
  /* display: flex; */
  text-align: center;
  margin: 20px;
  height: 100vh;
}

.thanks {
  text-align: justify;
  /* max-width: 500px;
  align-self: center; */
}

.thanksDiv {
  max-width: 500px;
  margin: auto;
  margin-top: 20px;
}

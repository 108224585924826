#upload-photo {
  /* opacity: 0;
  position: absolute;
  z-index: -1; */

  height: 0px;
  width: 0px;
  opacity: 0;
}

.addImageButton {
  /* background-color: red; */
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  border: lightgray 2px solid;
  border-radius: 10px;
  cursor: pointer;
  margin-left: 10px;
  margin-top: 5px;
}
.boldTx {
  font-weight: bold;
}

.checkboxAndLabelContainer {
  display: flex;
  margin: auto;
  width: 100%;
  height: 20px;
  /* justify-content: center; */
  /* margin-top: -8px; */
  /* margin-bottom: 15px; */
  /* background-color: red; */
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
}

.checkboxLabel {
  font-size: 13px;
  font-weight: 300;
  color: gray;
  display: inline;
  vertical-align: middle;
  margin-left: 5px;
  margin-top: 5px;
  /* background-color: blue; */
}

.createListingButton {
  width: 100%;
  border-radius: 10px;
  border: none;
  outline: none;
  height: 35px;
  background: #198754;
  color: #fff;
  font-size: 14px;
  margin-bottom: 10px;
  cursor: pointer;
}

.form {
  border-radius: 10px;
  width: 90%;
  max-width: 750px;
  /* height: 320px; */
  background-color: white;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  padding: 25px 30px;
  max-height: 90vh;
  overflow-y: auto;
}

.form > h1 {
  text-align: center;
}
.form > h2 {
  font-size: 1rem;
  margin-bottom: 0px;
}

.form > h3 {
  font-size: 0.8rem;
}
.grayInfoText {
  color: gray;
  margin-top: 10px;
  /* padding: 15px; */
  /* background-color: lightgray; */
}
.grayInfoTextHeader {
  color: gray;
  margin-top: 10px;
  /* text-decoration: underline; */
  cursor: pointer;
  /* padding: 15px; */
  /* background-color: lightgray; */
  /* border-bottom: gray 1px solid; */
  align-items: center;
}

.imagePreview {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

input[name="canShipCheckbox"] {
  height: 18px;
  width: 18px;
  /* margin-top: 1px; */
  background-color: blue;
}

.modally {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  justify-content: center;
  align-items: center;

  font-family: "Poppins", sans-serif;
  z-index: 5;
}

.selectedCitiesContainer {
  display: flex;
  /* background-color: red; */
  /* max-width: 100%; */
  height: 100%;
  margin-bottom: 10px;
  flex-wrap: wrap;
}
.standardInput {
  margin-top: 10px;
  /* margin-bottom: 20px; */
}

.sub {
  margin-bottom: 0px;
  margin-top: 5px;
}
.subHeader2 {
  font-size: 13px;
  font-weight: 300;
  color: gray;
  display: inline;
  vertical-align: middle;
}

.validationError {
  font-size: 13px;
  font-weight: 300;
  color: red;
  display: inline;
  /* vertical-align: middle; */
  /* margin-top: 0;
    padding-top: 0; */
}

.footerDiv {
  background-color: rgb(2, 86, 63);
  color: white;
  /* align-items: center; */
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 30px;
  padding-bottom: 10px;

  text-align: center;
}

.footer_icon {
  object-fit: contain;
  /* height: 40px; */
  /* height: 70px; */
  max-width: 50%;
  /* max-width: fit-content; */
  /* padding-right: 10px; */
  /* margin-left: 20px; */
  /* border: red 1px solid; */
  width: 250px;
  margin-bottom: 10px;
}

.instaLink {
  cursor: pointer;
  color: white;
  text-decoration: none;
}
